<template>
  <div style="width: 100% ;padding: 10px">
    <div style="margin: 10px 0">
      <div style="margin: 10px 0">
        <el-tooltip content="支持模糊搜索，不区分大小写" placement="top" effect="light">
          <el-input v-model="search" placeholder="请输入型号/图纸号/厂商/工件总数" style="width:35%"></el-input>
        </el-tooltip>
        <el-button type="primary" style="margin-left:5px" @click = "getTableDataShow">查询</el-button>
        <el-button type="primary" @click="remove">重置</el-button>
        <el-button type="primary">
          <div title="确定要清除缓存并刷新吗？这可能需要一些时间" @click="clean">清除缓存刷新
          </div>
        </el-button>
      </div>
    </div>
    <el-table
        :data="tableDataShow"
        style="width: 95%">
      <el-table-column
          prop="xinghao"
          label="型号"
          width="150">
      </el-table-column>
      <el-table-column
          prop="totalNum"
          label="工件总数"
          width="70">
      </el-table-column>
      <el-table-column
          prop="feijainNum"
          label="废件数"
          width="70">
        <template slot-scope="scope">
          <el-button
              type="text"
              size="small"
              @click = "errorId = scope.row.id,errorShow = true">
            {{scope.row.feijainNum}}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          prop="zhuangtai"
          label="状态"
          v-if="false"
          width="70">
      </el-table-column>
      <el-table-column
          label="进度"
          width="700">
        <template slot-scope="scope">
          <progress-bar-gropu v-if="upLode" :progresses="scope.row.gongxuList" :gongjian-id="scope.row.id"/>
<!--          <div style="width: 580px" v-if='scope.row.status == "success"'><el-progress :text-inside="true" :stroke-width="24" :percentage="scope.row.pre" status="success"></el-progress></div>-->
<!--          <div style="width: 580px" v-if='scope.row.status == "exception"'><el-progress :text-inside="true" :stroke-width="24" :percentage="scope.row.pre" status="exception"></el-progress></div>-->
<!--          <div style="width: 580px" v-if='scope.row.status == "warning"'><el-progress :text-inside="true" :stroke-width="24" :percentage="scope.row.pre" status="warning"></el-progress></div>-->
<!--          <div style="width: 580px" v-if='scope.row.status != "success" && scope.row.status != "exception" &&scope.row.status != "warning"'><el-progress :text-inside="true" :stroke-width="24" :percentage="scope.row.pre"></el-progress></div>-->
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          width="90">
        <template slot-scope="scope">
          <el-button
              type="text"
              size="small"
              @click = "detailShow(scope.row)">
            详情
          </el-button>
          <el-button
              type="text"
              size="small"
              v-if='scope.row.status == "success" || scope.row.status == "unfinishedSuccess"'
              @click = "rukuCheck(scope.row.id)">
            入库
          </el-button>
        </template>
      </el-table-column>
<!--      <el-table-column-->
<!--          prop="gongxuDoing"-->
<!--          label="所在工序"-->
<!--          width="200">-->
<!--      </el-table-column>-->
    </el-table>
    <div style="margin: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[5, 10, 20]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog title="加工人员" :visible.sync="jiagongrenformListshow">
      <el-table
          :data="jiagongrenformList">
        <el-table-column type="expand">
          <template slot-scope="propser">
            <el-table
                :data="propser.row.processFeiJianList">
              <el-table-column
                  label="废件标签"
                  prop="scrapLabels">
              </el-table-column>
              <el-table-column
                  label="废件数量"
                  prop="scrapNumbers">
              </el-table-column>
              <el-table-column
                  label="备注"
                  prop="scrapNotes">
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column
            label="加工人"
            prop="personName">
        </el-table-column>
        <el-table-column
            label="加工数量"
            prop="num">
        </el-table-column>
        <el-table-column
            label="废件数量"
            prop="nonum">
        </el-table-column>
      </el-table>
    </el-dialog>

<!--  侧边栏  -->
    <el-drawer
        title="详情信息"
        :visible.sync="drawer">
      <div style="padding: 10px">
        <el-card class="box-card">
          <el-descriptions >
            <el-descriptions-item label="型号">{{detailform.xinghao}}</el-descriptions-item>
            <el-descriptions-item label="废件数">{{detailform.feijainNum}}</el-descriptions-item>
            <el-descriptions-item label="总件数" :span="2">{{detailform.totalNum}}</el-descriptions-item>
            <el-descriptions-item label="厂商">{{detailform.changjia}}</el-descriptions-item>
            <el-descriptions-item label="图纸号">{{detailform.tuzhihao}}</el-descriptions-item>
          </el-descriptions>
        </el-card>
        <div style="padding: 18px 0;width: 80%;display: flex">
          <div>废品率 : <el-progress type="dashboard" :percentage="detailform.feijianPre" :color="colors"></el-progress></div>
        </div>
        <div style="padding: 18px 0;width: 80%;display: flex">
<!--          <el-collapse v-model="detailform.gongxuList">-->
<!--            <el-collapse-item title="工序详情" name="11" style="width: 400px">-->
<!--              <el-card class="box-card2">-->
<!--                <el-collapse accordion>-->
<!--                  <el-collapse-item v-for="(item,o) in detailform.gongxuList">-->
<!--                    <template slot="title">-->
<!--                      <el-tooltip placement="left" effect="light">-->
<!--                        <div slot="content">{{item.gongxu}}</div>-->
<!--                        <el-button type="text" size="small" STYLE="width: 120px">{{item.gongxu | ellipsis}}</el-button>-->
<!--                      </el-tooltip>-->
<!--                      <div style="display:flex;width: 350px">-->
<!--                        <div style="width: 80%" v-if='item.status == "exception"'><el-progress :percentage="item.pre" status="exception"></el-progress></div>-->
<!--                        <div style="width: 80%" v-if='item.status == "warning"'><el-progress :percentage="item.pre" status="warning"></el-progress></div>-->
<!--                        <div style="width: 80%" v-if='item.status == "success"'><el-progress :percentage="item.pre" status="success"></el-progress></div>-->
<!--                        <div style="width: 80%" v-if='item.status != "exception" && item.status != "warning" && item.status != "success"'><el-progress :percentage="item.pre"></el-progress></div>-->
<!--                      </div>-->
<!--                    </template>-->
<!--                    <div style="display: flex"></div>-->
<!--                    <div><div>废件数 : {{item.feijianNum}}</div>-->
<!--                      <el-collapse>-->
<!--                        <el-collapse-item style="width: 320px;">-->
<!--                          <template slot="title">-->
<!--                            <div style="display:flex;">废件信息 :-->
<!--                              <div v-for="(itemtitle,p) in item.feijianxinxi" style="display:flex">-->
<!--                                <div style="width: 50px;" >{{itemtitle.scrapLabels}}: {{itemtitle.num}}</div>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </template>-->
<!--                          <div v-for="(itembody,q) in item.feiJianList">-->
<!--                            <el-descriptions >-->
<!--                              <el-descriptions-item label="废件标签">{{itembody.scrapLabels}}</el-descriptions-item>-->
<!--                              <el-descriptions-item label="废件数">{{itembody.scrapNumbers}}</el-descriptions-item>-->
<!--                              <el-descriptions-item label="人员" :span="2">{{itembody.yuangongName}}</el-descriptions-item>-->
<!--                              <el-descriptions-item label="备注">{{itembody.scrapNotes}}</el-descriptions-item>-->
<!--                            </el-descriptions>-->
<!--                            <el-divider></el-divider>-->
<!--                          </div>-->
<!--                        </el-collapse-item>-->
<!--                      </el-collapse>-->
<!--                      <div>加工人 : <el-button type="text" size="small" @click = "renyuanshow(item.renyuanxinxi)">-->
<!--                        {{item.renyuanList}}-->
<!--                      </el-button></div>-->
<!--                    <div>目标加工数 : {{item.mubiaoNum}}</div>-->
<!--                    <div>已加工数 : {{item.workedNum}}</div></div>-->
<!--                    <div>-->
<!--                      <div>废品率 : <el-progress type="dashboard" :percentage="item.feijianPre" :color="colors"></el-progress></div>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                      <div>预计完工日期 :-->
<!--                        <el-date-picker-->
<!--                            v-model="item.finishDate"-->
<!--                            v-if="item.finishDate"-->
<!--                            type="date"-->
<!--                            disabled="true"-->
<!--                            placeholder="选择日期">-->
<!--                        </el-date-picker>-->
<!--                        <el-button type="text" @click="datedialog = true,finishDate = item.finishDate,finishGongjina = detailform.id , finishGongxu = item.gongxu">编辑</el-button>-->
<!--                      </div>-->
<!--&lt;!&ndash;                      <div>预计完工日期 : {{item.gongxu}}</div>&ndash;&gt;-->
<!--                    </div>-->
<!--                  </el-collapse-item>-->
<!--                </el-collapse>-->
<!--              </el-card>-->
<!--            </el-collapse-item>-->
<!--          </el-collapse>-->
        </div>
      </div>
    </el-drawer>
    <el-dialog
        title="设置最后日期"
        :visible.sync="datedialog"
        width="30%">
      <el-date-picker
        v-model="finishDate"
        type="date"
        placeholder="选择日期">
      </el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="updateFinishDate()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="废件详情"
        :visible.sync="errorShow"
        width="30%">
      <error-products :id="errorId" v-if="errorShow"/>
    </el-dialog>
    <div v-show="rukuCheckSize !== 0">
      <el-dialog
          title="废件处理"
          :visible.sync="rukuCheckType"
          :before-close="handleClose"
          width="80%">
        <nonConformingProducts v-if="rukuCheckType" :searchId="rukuCheckId" @errorList="errorListSizeCheck"/>
      </el-dialog>
    </div>

  </div>
</template>

<script>

import request from "@/utils/request";
import progressBarGropu from "./element/progress_bar_group"
import errorProducts from "@/views/element/error_products_list";
import nonConformingProducts from "@/views/NonConformingProducts.vue";

export default {
  components: { progressBarGropu , errorProducts , nonConformingProducts},
  data() {
    return {
      drawer: false,
      search:'',
      currentPage4:1,
      size: 10,
      total:10,
      tableData: [],
      tableDataShow: [],
      detailform:{},
      jiagongrenformListshow: false,
      jiagongrenformList:[],
      colors: [
        {color: '#f56c6c', percentage: 20},
        {color: '#e6be3c', percentage: 40},
        {color: '#cdff4e', percentage: 60},
        {color: '#5cb87a', percentage: 80},
      ],
      datedialog: false,
      finishDate: null,
      finishGongjina: null,
      finishGongxu: null,
      upLode:true,
      errorId:0,
      errorShow:false,

      //入库校验
      rukuCheckType:false,
      rukuCheckId:0,
      rukuCheckSize:0,
    }
  },
  filters:{
    ellipsis(value){
      if (!value) return '';
      if (value.length > 7) {
        return value.slice(0,6) + '...'
      }
      return value
    }
  },
  //加载表格
  mounted() {
    this.select()
  },
  methods:{
    getFinishDate(gongjian,gongxu){
      let item = {
        gongjian:gongjian,
        gongxu:gongxu
      }
      request.post("/getFinishDate",item).then(res => {
        if (res.code === '200') {
          // console.log("finishDate",res.data.finishDate)
          // return res.data.finishDate;
          return "2007-04-22"
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => {
        return null;
      });
    },
    updateFinishDate(gongjian,gongxu){
      this.datedialog = false;
      let item = {
        gongjian:this.finishGongjina,
        gongxu:this.finishGongxu,
        finishDate:this.finishDate,
      }
      request.post("/updateFinishDate",item).then(res => {
        if (res.code === '200') {
          this.select();
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => {
        return null;
      });
    },
    clean(){
      this.$confirm('确定要清除缓存并刷新吗？这可能需要一些时间', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        request.post("/ProgressClean").then(res => {
          if (res.code === '200') {
            this.select()
          } else {
            this.$message.error(res.msg)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消刷新'
        });
      });
    },
    //整表查询
    renyuanshow(formList){
      this.jiagongrenformListshow = true
      this.jiagongrenformList = formList;
    },
    select() {
      this.tableData = [];
      this.tableDataShow = [];
      request.post("/Progress").then(res => {
        if (res.code === '200') {
          this.tableData = res.data
          this.detailform = this.tableData[0]
          this.total = res.data.length;
          this.getTableDataShow();
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    rukuCheck(id){
      this.rukuCheckType = true;
      this.rukuCheckId = id;
    },
    errorListSizeCheck(msg){
      this.rukuCheckSize = msg
      if (msg === 0){
        this.rukuCheckType = false;
        this.ruku(this.rukuCheckId)
      }
    },
    //整表查询
    ruku(id) {
      request.post("/Ruku" ,id).then(res => {
        if (res.code === '200') {
          this.select();
          if (res.msg.includes("[ERROR:]")){
            this.$message.error("入库单自动创建失败，请手动创建入库单")
          } else {
            this.$message.success("入库成功")
          }
        } else {
          this.$message.error(res.msg)
        }
      })

      this.rukuCheckId = 0;
    },
    //详情展示
    detailShow(row) {
      this.detailform = row;
      this.drawer = true;
    },
    //详情展示
    getPercent(fenzi,fenmu) {
      let num = (fenzi*100)/fenmu
      return alert(num.toFixed(2))
    },
    //重置
    remove(){
      this.search=''
      this.getTableDataShow();
    },
    handleSizeChange(val) {
      this.upLode = false;
      this.size = val;
      this.getTableDataShow();
      this.$nextTick(()=>{
        this.upLode = true;
      })
      //console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.upLode = false;
      this.currentPage4 = val;
      this.getTableDataShow();
      this.$nextTick(()=>{
        this.upLode = true;
      })
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            this.rukuCheckSize = 0
            done();
          })
          .catch(_ => {
          });
    },
    async getTableDataShow(){
      let _tableData = (this.search === undefined || this.search === null || this.search === "") ? this.tableData : this.tableData.concat().filter(item => {
        //型号匹配
        try{
          if (item.xinghao.toLowerCase().toString().includes(this.search.toLowerCase().toString())){
            return true
          }
        }catch (e) {}
        //图纸号匹配
        try{
          if (item.tuzhihao.toLowerCase().toString().includes(this.search.toLowerCase().toString())){
            return true
          }
        }catch (e) {}
        //厂商匹配
        try{
          if (item.changjia.toLowerCase().toString().includes(this.search.toLowerCase().toString())){
            return true
          }
        }catch (e) {}
        //总数匹配
        try{
          if ( item.totalNum.toString() === this.search.toString()){
            return true
          }
        }catch (e) {}
        //包含工序匹配
        // try{
        //   if (item.gongxuList.indexOf(ch => {
        //     console.log(ch)
        //     return ch.gongxu.toString().includes(this.search)
        //   }) != -1){
        //     return true
        //   }
        // }catch (e) {}
        return false
      })
      let _pageMax = Math.ceil(_tableData.length/this.size)
      this.currentPage4 = this.currentPage4>_pageMax ? _pageMax :this.currentPage4;
      this.currentPage4 = this.currentPage4 < 1 ? 1 : this.currentPage4

      this.tableDataShow = _tableData.slice((this.currentPage4-1)*this.size,this.currentPage4*this.size);

      this.$nextTick(() => {
        this.total = _tableData.length
        // this.formLoading = false;
      })
    },
    onSubmit() {
      console.log('submit!');
    }
  }
}

</script>

<style scoped>
.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.box-card {
  width: 440px;
}

.box-card2 {
  width: 390px;
}
</style>